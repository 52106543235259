<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Activity Name</th>
          <th>Activity Short Name</th>
          <th>Activity ID</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in activities" :key="i">
          <td> {{ item.name }}</td>
          <td>{{ item.short_name }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.status }}</td>
          <td style="cursor: pointer">
            <span @click="goToView(item.id)">View</span>
            /
            <span @click="goToEdit({...item})">Edit</span>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!activities.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityListingTable",
  props: {
    activities: []
  },
  methods: {

    goToEdit(data) {
      this.$emit('onEdit', data);
    },

    goToView(id) {
      // TODO,
    }
  }
}
</script>