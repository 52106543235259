import Network from '@/services/network';
import {ref} from 'vue';

export default function handleActivities() {
    const network = new Network;
    const loading = ref(false);
    const uri = '/procurement/organization-setting/activity';

    const fetchActivityList = (query) => {
        return network.api('get', uri + query);
    }

    const createNewActivity = (data) => {
        return network.api('post', uri, data);
    }

    const updateActivity = (id, data) => {
        return network.api('put', `${uri}/${id}`, data);
    }


    return {
        loading,
        fetchActivityList,
        createNewActivity,
        updateActivity,
    }
}